import './style.css';
import {Map, View} from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import Print from 'ol-ext/control/Print';
import { useGeographic } from 'ol/proj';

useGeographic();

const map = new Map({
  target: 'map',
  layers: [
    new TileLayer({
      source: new OSM()
    })
  ],
  view: new View({
    center: [5, 52.2],
    zoom: 8
  })
});

const printControl = new Print({
  quality: 1
});

// Add the Print control to the map
map.addControl(printControl);

// Register a callback function for the 'print' event
printControl.on('print', (event) => {
  console.log('Print operation completed', event);
  // Your base64 string representing a JPG image
const base64String = event.image; // Replace with your actual base64 string

// Convert the base64 string to a Blob
const byteCharacters = atob(base64String.split(',')[1]);
const byteNumbers = new Array(byteCharacters.length);
for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
}
const byteArray = new Uint8Array(byteNumbers);
const blob = new Blob([byteArray], { type: 'image/jpeg', quality: 1 });

// Create a link element
const downloadLink = document.createElement('a');

// Set the href attribute to the Blob URL
downloadLink.href = URL.createObjectURL(blob);

// Set the download attribute with the desired filename
downloadLink.download = 'map.jpg';

// Append the link to the document
document.body.appendChild(downloadLink);

// Trigger a click on the link to initiate the download
downloadLink.click();

// Remove the link from the document (optional)
document.body.removeChild(downloadLink);

});

// Alternatively, you can use the 'beforeprint' event to set up a callback before printing starts
printControl.on('beforeprint', () => {
  console.log('Before print operation');
});

map.on('click', (event) => {
  console.log('Click event:', event);
});

// Function to export the map as PNG
// const exportMapAsPNG = async () => {
//   const result = olmap.print();
//   console.log(result);
// };

// exportMapAsPNG();

// Assuming you have an OpenLayers map
const map2 = new Map({
  // ... your map configuration ...
  layers: [
    new TileLayer({
      source: new OSM()
    })
  ],
  view: new View({
    center: [0, 0],
    zoom: 2
  })
});